import { Send } from "@mui/icons-material";
import { Alert, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import apis from "../../../api";
import { Title } from "../../../Component/MuiEx";
import MyTable from "../../../Component/MyTable";
import { apiResult, getUserSession } from "../../../Utils/Common";
const MailDialog =(props)=>{
    const {receives} = props
    const [error, setError] = useState()
    const session = getUserSession(apis)
    const [templates, setTemplates] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [selected,setSelected] = useState()
    const loadTemplates = (page, pagesize, countData) => {        
        apis.loadTemplates(page, pagesize, countData).then(ret => {            
            apiResult(ret, (data) => {
                countData == 1 && setTotalCount(data.total)
                setTemplates(data.data)                
            }, setError)
        })
    }
    let initPage = false
    useEffect(() => {
        if (initPage) return
        initPage = true
        loadTemplates(0, 20, 1)
    }, [])
    const handleChangePage = (page, rowsperpage) => {
        //setRowsPerPage(rowsperpage)
        loadTemplates(page, rowsperpage, 0)
    }
    const handleSelected=(id)=>{
        setSelected(id)
    }
    const handleSend = ()=>{
        if(selected>=0) {
            apis.sendMail({tid:selected,receives:receives}).then(ret=>{
                apiResult(ret,(data)=>{

                },setError)
            })
        }else {
            setError("You need to choose a email template")
        }        
    }
    const TemplateTableHeader = [        
        { name: 'name', showName: 'Template name' },
        { name: 'description', showName: 'Description' },]
    return <Box sx={{ mt: 10 ,p:2,maxWidth:'500px'}}>
        <Title>Choose a email template</Title>
        {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
        {totalCount == 0 && <Alert severity={"info"}>Ops! There is not any templates, try to add one</Alert>}
            {totalCount > 0 && <MyTable
                height={650}
                rows={templates}
                totalRow={totalCount}
                headers={TemplateTableHeader}
                checkbox={true}
                onSelected={handleSelected}
                singleOption={true}
                rowsPerPage={20}                
                onChangePage={handleChangePage}                
            />}
        <Button variant="contained" sx={{mt:2}} onClick={handleSend} endIcon={<Send />}>Send</Button>
    </Box>
}
export default MailDialog