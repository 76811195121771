import MyTable from "../../Component/MyTable"
import CustomerBar from "./fragement/CustomerBar";
import { Alert, Button, Chip, Snackbar, Backdrop, Box, Typography, Stack, Avatar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { apiResult, getUserSession } from "../../Utils/Common";
import apis from "../../api";
import { GameCategory, GameCategoryColor, MemberLevel, MemberStatus, RightDrawer, } from "../../Component/MuiEx";
import CustomerOptButton from "./fragement/CustomerOptButton";
import GroupList from "./fragement/GroupList"

import MemberEditForm from "./fragement/MemberEditForm";
import { useNavigate } from "react-router";
import MemberSettingDialog from "./fragement/MemberSettingDialog";
import { loadCoaches } from "../../App/DataLoad";
import { getCoachName } from "../../Component/CoachSelector";

const Members = () => {
    const [selected, setSelected] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentMemberIdx, setCurrentMemberIdx] = useState(-1)    
    const [hintMsg, setHintMsg] = useState()
    const [customers, setCustomers] = useState([])
    const [filters, setFilters] = useState()
    const [currentGroup, setCurrentGroup] = useState()
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [totalCount, setTotalCount] = useState(0)
    const [currentQuery, setCurrentQuery] = useState()
    const [coachs,setCoachs] = useState()
    const [error, setError] = useState()
    //const [rightComponent, setRightComponent] = useState()   //right drawer children
    const [rightDrawer, setRightDrawer] = useState()   //open or close right drawer
    const [autoCloseRightDrawer, setAutoCloseRightDrawer] = useState(true)
    const navigate = useNavigate()
    const showOption = useRef(-2)
    const categoryOption = useRef(-2)
    const handleOrder = (item) => {
        const neworder = orderField.order == 'desc' ? 'asc' : 'desc'
        setOrderField({ ...orderField, name: item.name, order: neworder })
        currentQuery && currentQuery.action == 'loadCustomers' && loadCustomer(0, rowsPerPage, 0, item.name, neworder)
    }
    const [orderField, setOrderField] = useState({ fields: ['id', 'name', 'birthday', 'level', 'level_date', 'category'], name: 'id', order: 'desc' })
    const initPage = useRef(false)
    getUserSession(apis)
    // const createData = (id, membership, name, city, province, create_time) => {
    //     return { id, membership, name, city, province, create_time }
    // }

    const handleHintClose = () => {
        setHintMsg()
    }
    const loadCustomer = (page, pagesize, countData, orderfield, order) => {
        setLoading(true)
        loadCoaches(apis,(data)=>setCoachs(data),setError)
        apis.loadCustomer(page, pagesize, countData, showOption.current, categoryOption.current, orderfield, order).then(ret => {
            setLoading(false)
            apiResult(ret, (data) => {
                countData === 1 && setTotalCount(data.total)
                customers.length > 0 && setHintMsg('Loaded data successfully')
                data.data.map(c => {
                    c.parent = c.user_profile ? c.user_profile.name : ''
                    c.parent_phone = c.user_profile ? (c.user_profile.phone) : ''
                    c.parent_email = c.user_profile ? (c.user_profile.email) : ''
                    return true
                })
                setCustomers(data.data)
                setCurrentQuery({ action: 'loadCustomers' })
                setSelected([])
            }, setError)
        })
    }

    useEffect(() => {
        if (initPage.current) return
        initPage.current = true
        loadCustomer(0, rowsPerPage, 1, 'id', 'desc')
    }, [])
    const handleEditCustomerFormClose = (changed) => {
        setRightDrawer()
        if (changed) {
            loadCustomer(0, rowsPerPage, 1, orderField.name, orderField.order)
        }
    }
    // const handleAddCustomer = () => {
    //     setRightDrawer(<MemberEditForm id={0} onClose={handleEditCustomerFormClose} />)
    //     setAutoCloseRightDrawer(false)
    // }
    const handleRightDrawer = () => {
        autoCloseRightDrawer && setRightDrawer(false)
    }
    const handleSelected = (selects) => {
        setSelected(selects)

    }
    // const handleCustomerDetailClose = (changed) => {
    //     setRightDrawer()
    // }
    const handleCustomerDetail = (id, idx) => {
        // setAutoCloseRightDrawer(false)
        // setRightDrawer(<CustomerDetail onClose={handleCustomerDetailClose} />)
        navigate('/accounting/orders/' + customers[idx].user_id + "/" + id)
    }
    const handleEditCustomer = (id, idx) => {
        setAutoCloseRightDrawer(false)
        setRightDrawer(<MemberEditForm id={id} onClose={handleEditCustomerFormClose} />)
    }
    const handleChangePage = (page, rowsperpage) => {
        //setRowsPerPage(rowsperpage)
        currentQuery.action === 'loadCustomers' && loadCustomer(page, rowsperpage, 0, orderField.name, orderField.order)
        currentQuery.action === 'filterByGroup' && filterByGroup(currentQuery.p1, currentQuery.p2, page, rowsperpage, 0)
        currentQuery.action === 'searchCustomers' && searchCustomers(currentQuery.p1, page, rowsperpage, 0)
    }
    const handleChangeRowsPerPage = (rowsperpage) => {
        setRowsPerPage(rowsperpage)
        setError()
        currentQuery.action === 'loadCustomers' && loadCustomer(0, rowsperpage, 0, orderField.name, orderField.order)
        currentQuery.action === 'filterByGroup' && filterByGroup(currentQuery.p1, currentQuery.p2, 0, rowsperpage, 0)
        currentQuery.action === 'searchCustomers' && searchCustomers(currentQuery.p1, 0, rowsperpage, 0)
    }
    const filterByGroup = (id, groups, page, rowperpage, countdata) => {
        if (id > 0) {
            setLoading(true)
            apis.getGroupCustomers(id, page, rowperpage, countdata, showOption.current, categoryOption.current).then(ret => {
                setLoading(false)
                apiResult(ret, (data) => {
                    if (groups && groups.length > 0) {
                        let ss = ''
                        groups.map(group => {
                            ss += group.name + '  '
                        })
                        ss = ss.length > 20 ? (ss.substring(0, 20) + '...') : ss
                        setFilters(<Chip sx={{ marginBottom: 1 }} label={ss} variant="outlined" onDelete={() => { setFilters(); setCurrentGroup(); loadCustomer(0, rowsPerPage, 1, orderField.name, orderField.order) }} />)
                        if (groups.length === 1) {
                            setCurrentGroup(groups[0])
                        } else {
                            setCurrentGroup()
                        }
                    }
                    data.data.map(c => {
                        c.parent = c.user_profile ? c.user_profile.name : ''
                        c.parent_phone = c.user_profile ? (c.user_profile.phone) : ''
                        c.parent_email = c.user_profile ? (c.user_profile.email) : ''
                    })
                    setCustomers(data.data)
                    if (countdata == 1) {
                        setTotalCount(data.total)
                    }
                    setHintMsg('Loaded data successfully')
                    setCurrentQuery({ action: 'filterByGroup', p1: id, p2: groups })
                }, setError)
            })

        }
    }
    const handleFilterByGroup = () => {
        setRightDrawer(<GroupList showgroup={true} onClose={
            (id, groups) => { setError(); id && filterByGroup(id, groups, 0, rowsPerPage, 1); setRightDrawer() }
        } multiple={false} />)
    }

    const addToGroup = (ids) => {
        if (ids) {
            setError()
            apis.addToGroup(selected, ids).then(ret => {
                apiResult(ret, (data) => {
                    setHintMsg('Added Successfully')
                }, setError)
            })
        }
        setRightDrawer()
    }
    const handleAddToGroup = () => {
        setRightDrawer(<GroupList showgroup={true} onClose={addToGroup} multiple={true} />)
    }
    const removeItemFromList = (ids) => {
        let cc = [...customers]
        for (let i = cc.length - 1; i >= 0; i--) {
            if (ids.indexOf(cc[i].id) >= 0) {
                cc.splice(i, 1)
            }
        }
        setCustomers(cc)
    }
    const handleDormantFromGroup = () => {
        if (currentGroup) {
            setError()
            apis.removeFromGroup({ ids: selected, groupid: currentGroup.id }).then(ret => {
                apiResult(ret, (data) => {
                    removeItemFromList(selected)
                    setHintMsg('Succeed to remove ' + data + ' customer from the group')
                }, setError)
            })
        }
    }

    const searchCustomers = (value, page, rowperpage, countdata) => {
        setLoading(true)
        apis.customerSearch(value, page, rowperpage, countdata, showOption.current, categoryOption.current).then(ret => {
            setLoading(false)
            apiResult(ret, (data) => {
                (countdata == 1) && setTotalCount(data.total)
                data.data.map(c => {
                    c.parent = c.user_profile ? c.user_profile.name : ''
                    c.parent_phone = c.user_profile ? (c.user_profile.phone) : ''
                    c.parent_email = c.user_profile ? (c.user_profile.email) : ''
                })
                setCustomers(data.data)
                setFilters(<Chip sx={{ marginBottom: 1 }} label={value} variant="outlined" onDelete={() => { setFilters(); loadCustomer(0, rowsPerPage, 1, orderField.name, orderField.order) }} />)
                setCurrentQuery({ action: 'searchCustomers', p1: value })
            }, setError, (errors) => {
                setError(errors.value)
            })
        })

    }
    const handleCustomerSearch = (event) => {
        setError()
        event.preventDefault();

        const postdata = new FormData(event.currentTarget);
        searchCustomers(postdata.get('value'), 0, rowsPerPage, 1)
    }
    const handleCloseSecondBar = () => {
        setSelected()
    }
    const changeStatus = (id, status, idx) => {
        apis.changeMemberStatus(id, status).then(ret => {
            apiResult(ret, data => {
                let cc = [...customers]
                cc[idx].status = status
                setCustomers(cc)
            }, setError)
        })
    }
    const handleNonMember = (id, idx) => {
        changeStatus(id, 0, idx)
    }
    const hanleMember = (id, idx) => {
        changeStatus(id, 1, idx)
    }
    const handleTempMember = (id, idx) => {
        changeStatus(id, 2, idx)
    }
    const handleDormant = (id, idx) => {
        changeStatus(id, 3, idx)
    }
    const handleInactive = (id, idx) => {
        changeStatus(id, 4, idx)
    }
    const handleShowOption = (name, value) => {
        showOption.current = value
        currentQuery.action === 'loadCustomers' && loadCustomer(0, rowsPerPage, 1, orderField.name, orderField.order)
        currentQuery.action === 'filterByGroup' && filterByGroup(currentQuery.p1, currentQuery.p2, 1, rowsPerPage, 0)
        currentQuery.action === 'searchCustomers' && searchCustomers(currentQuery.p1, 0, rowsPerPage, 1)
    }
    const handleCategoryOption = (name, value) => {
        categoryOption.current = value
        currentQuery.action === 'loadCustomers' && loadCustomer(0, rowsPerPage, 1, orderField.name, orderField.order)
        currentQuery.action === 'filterByGroup' && filterByGroup(currentQuery.p1, currentQuery.p2, 1, rowsPerPage, 0)
        currentQuery.action === 'searchCustomers' && searchCustomers(currentQuery.p1, 0, rowsPerPage, 1)
    }
    const handleMemberLog = (id) => {
        navigate('/member/levellog/' + id)
    }

    const CustomerTableHeader = [
        { name: 'id', showName: 'ID' },
        {
            name: 'name', showName: 'Member', func: (v, idx, row) => {
                const { icon, status } = MemberStatus(row.status)
                let memberid = ""
                if (row.status > 0) {
                    let n = 1000 + Number(row.id)
                    memberid = "1" + (row.gender == 'Male' ? "1" : "0") + ((row.birthday && row.birthday != null) ? row.birthday.substring(0, 4) : '0000') + n
                }
                return  <Stack direction={"row"}>
                        {row.photo && <Avatar sx={{cursor:"pointer"}} onClick={()=>{window.open("/api/resource/photo?file="+row.photo)}} alt="" src={"/api/resource/photo?file="+row.photo}></Avatar>}
                        {!row.photo && <Avatar alt="" src={"/person.jpg"}></Avatar>}
                        <Stack sx={{ml:1,mr:2}} direction={"column"}>
                            <Typography variant="subtitle">{v}</Typography>
                            <Typography sx={{ color: "gray", fontSize: '12px' }}>{memberid}</Typography>
                        </Stack>
                        {row.status != 1 && <Chip size="small" variant="outlined" title={status} icon={icon} sx={{border:"0px"}}/>}
                    </Stack>
                // return <Chip size="small" variant="outlined" title={status} icon={icon}
                //     label={<Stack direction={"column"}>
                //         <Typography variant="subtitle">{v}</Typography>
                //         <Typography sx={{ color: "gray", fontSize: '12px' }}>{memberid}</Typography>
                //     </Stack>} sx={{ border: "1px" }} />

            }
        },
        {
            name: 'birthday', showName: "Birthday", func: (v, idx, row) => {
                return (v ? v : '') + (row.birthday && row.birthday.length > 0 ? (" #" + (Number(new Date().getFullYear()) - Number(new Date(row.birthday).getFullYear()))) : '')
            }
        },
        {
            name: 'parent_phone', showName: 'Parent phone', func: (v, idx, row) => {
                if (v && v != null) {
                    v = v.substring(3, 4) != '-' ? (v.substring(0, 3) + '-' + v.substring(3)) : v
                    v = v.substring(7, 8) != '-' ? (v.substring(0, 7) + '-' + v.substring(7)) : v
                    return <Stack direction={"column"}>
                        <Typography variant="subtitle">{row.parent}</Typography>
                        <Typography style={{ color: "gray" }} variant="body2">{v}</Typography>
                    </Stack>
                }
            }
        },
        {
            name: "category", showName: "Category", func: (v, idx, row) => {
                return <Stack sx={{cursor:"pointer"}} direction={"column"} onClick={()=>{setCurrentMemberIdx(idx)}}>
                <Typography variant="subtitle">{GameCategory[Number(v)]}{row.main_coach>0 && " - "+getCoachName(coachs,row.main_coach)}</Typography>
                {row.main_coach == 0 && <Typography style={{ color: "gray",fontSize:"10px"}} variant="body2">"Click to set main coach"</Typography>}
            </Stack>            
            }
        },
        {
            name: 'level', showName: 'Member Level', func: (v, idx, row) => {
                const { level, color } = MemberLevel(row.level)
                return <Chip size="small" variant="outlined" title={level + "@" + row.level_date} onClick={() => {
                    setCurrentMemberIdx(idx)
                }} label={level} sx={{ m: "1px", color: color }} />

            }
        },
        {
            name: 'level_date', showName: '@Date', func: (v, idx, row) => {
                return <Box>
                    <Typography>{v}</Typography>
                    {row.level_expired && <Typography variant="body2" sx={{ fontSize: "12px", mt: 0.5, color: "#888" }}>Expired: {row.level_expired}</Typography>}</Box>
            }
        }

    ]
    // const OptionButton = [
    //     {
    //         text: "", icon: <AddIcon fontSize="small" />, subItems: [
    //             { text: "Greate group", icon: <AddIcon fontSize="small" />, onClick: handleCreateGroup },
    //             { text: 'Add Customer', icon: <AddIcon fontSize="small" />, onClick: handleAddCustomer },
    //             { text: 'Import from file', icon: <UploadFileIcon fontSize="small" />, onClick: handleImportCustomerFile },
    //         ]
    //     },
    // ]
    return (
        <>
            {loading && <Backdrop />}
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}
            <CustomerBar
                onFilterByGroup={handleFilterByGroup}
                onMemberLevelLog={() => handleMemberLog(0)}
                onCustomerSearch={handleCustomerSearch}
                onCloseSecondBar={handleCloseSecondBar}
                onShowOption={handleShowOption}
                onCategoryOption={handleCategoryOption}
            >{selected && selected.length > 0 && <>
                <Button variant="contained" size="small" onClick={handleAddToGroup} sx={{ ml: 2, fontSize: 12, cursor: 'hand' }} > Group</Button>
                {currentGroup && <Button variant="outlined" size='small' onClick={handleDormantFromGroup} sx={{ ml: 2, fontSize: 12, width: 200, cursor: 'hand' }} >
                    Dormant from {currentGroup.name}
                </Button>}
            </>}
            </CustomerBar>
            {totalCount == 0 && <Alert severity={"info"}>Ops! There is not any memebrs</Alert>}

            {filters}

            {totalCount > 0 && <MyTable
                height={650}
                rows={customers}
                totalRow={totalCount}
                headers={CustomerTableHeader}
                checkbox={true}
                rowsPerPage={rowsPerPage}
                order={orderField}
                onOrder={handleOrder}
                OpentionComponent={(id, idx) => {
                    return <CustomerOptButton
                        onDetail={handleCustomerDetail}
                        onMemberLevelLog={handleMemberLog}
                        onEdit={handleEditCustomer}
                        onNonMember={handleNonMember}
                        onMember={hanleMember}
                        onTempMember={handleTempMember}
                        onDormant={handleDormant}
                        onInactive={handleInactive}
                        id={id} index={idx} />
                }}
                onSelected={handleSelected}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />}
            <RightDrawer toggleDrawer={handleRightDrawer} open={rightDrawer ? true : false}>
                {rightDrawer}
            </RightDrawer>
            {hintMsg && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={hintMsg ? true : false} autoHideDuration={3000} onClose={handleHintClose}>
                <Alert onClose={handleHintClose} severity="success" sx={{ width: '100%' }}>{hintMsg}</Alert>
            </Snackbar>}
            {currentMemberIdx >= 0 && <MemberSettingDialog member={customers[currentMemberIdx]} onClose={() => setCurrentMemberIdx(-1)} onSubmit={(data) => {
                setCurrentMemberIdx(-1)
                let cc = [...customers]
                data.level>=0 && ( cc[currentMemberIdx].level = data.level )
                data.level_date && (cc[currentMemberIdx].level_date = data.level_date )
                data.level_expired && (cc[currentMemberIdx].level_expired = data.level_expired )
                data.main_coach && (cc[currentMemberIdx].main_coach = data.main_coach )
                data.category && (cc[currentMemberIdx].category = data.category )
                setCustomers(cc)
            }} />
            }
        </>

    )
}
export default Members