import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import apis from "../api"
import { apiResult, getUserSession, sessionGet, sessionSet } from "../Utils/Common"
import { loadCoaches } from "../App/DataLoad"


const CoachSelect = (props) => {
    const { defaultValue, onChange, coaches, name, label, onError } = props
    const [_coaches, setCoaches] = useState(coaches)
    getUserSession(apis)
    useEffect(() => {
        if (!_coaches) {
            loadCoaches(apis, (data) => { setCoaches(data) }, onError)
        }
    }, [])
    return <FormControl fullWidth sx={props.sx}>
        <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
        <Select labelId="demo-simple-select-autowidth-label"
            id="product"
            onChange={(e) => {
                let id = e.target.value;
                let choice = false
                if (id > 0) {
                    for (let i = 0; i < _coaches.length; i++) {
                        if (id == _coaches[i].id) {
                            onChange && onChange(name, _coaches[i])
                            choice = true
                            break
                        }
                    }
                }
                if (!choice) {
                    onChange && onChange(name, { id: 0 })
                }
            }}
            defaultValue={defaultValue}
            label={label}>
            <MenuItem value={0}>Nothing</MenuItem>
            {_coaches && _coaches.map((p, idx) => {
                return <MenuItem key={idx} value={p.id}>{p.name}</MenuItem>
            })}
        </Select>
    </FormControl>
}

const getCoach = (coaches, id) => {
    for (let i = 0; i < coaches.length; i++) {
        if (coaches[i].id == id) {
            return coaches[i]
        }
    }
    return undefined
}
const getCoachName = (coaches, id) => {
    if (coaches && coaches.length > 0) {
        for (let i = 0; i < coaches.length; i++) {
            if (coaches[i].id == id) {
                return coaches[i].name
            }
        }
    }
    return "None"
}
export { CoachSelect, getCoach, getCoachName }