import { useState } from 'react';
import { Button, TextField, Grid, Box, Typography, Alert, } from '@mui/material';
import { formToJson } from '../../../Utils/Common';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { MultiSelector, SingleSelector } from '../../../Component/MuiEx';

const EditRacing = (props) => {
    const { data, onSubmit, onClose } = props
    const [fieldErrors, setFieldErrors] = useState()
    const [error, setError] = useState()
    const [sdate,setSDate] = useState()
    const handleChange = (n,v)=>{
        setSDate(v)
    }
    const handleSubmit = (event) => {
        setFieldErrors()
        setError()
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        const postData = formToJson(form)
        sdate && (postData.sdate = sdate)
        for(let key of Object.keys(postData)) {
            if(postData[key] == "" || postData[key] == null) {
                delete postData[key]
            }
        }
        if(Object.keys(postData).length == 0) {return}
        postData.id = data ? data.id : 0
        //postData.passwd && postData.passwd.length>0 && (postData.passwd = hex_md5(postData.passwd))
        onSubmit && onSubmit(postData, setError, setFieldErrors)
    };
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography component="h1" variant="h5">Add & Edit Role</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.name} name="name" required fullWidth id="name" label="Name"
                                error={fieldErrors && fieldErrors.name ? true : false}
                                helperText={fieldErrors && fieldErrors.name ? fieldErrors.name : ''}
                                autoFocus />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.description} multiline rows={6} name="description" fullWidth id="description" label="description"
                                error={fieldErrors && fieldErrors.description ? true : false}
                                helperText={fieldErrors && fieldErrors.description ? fieldErrors.description : ''}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.url} name="url" fullWidth id="url" label="url"
                                error={fieldErrors && fieldErrors.url ? true : false}
                                helperText={fieldErrors && fieldErrors.url ? fieldErrors.url : ''}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.picture} name="picture" fullWidth id="picture" label="picture"
                                error={fieldErrors && fieldErrors.picture ? true : false}
                                helperText={fieldErrors && fieldErrors.picture ? fieldErrors.picture : ''}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField type="date"
                                margin="normal"
                                sx={{ mt: "0" }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                label={"Date"}
                                value={data?.date}
                                onChange={(e) => { handleChange('sdate', e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.location} name="location" fullWidth id="location" label="location"
                                error={fieldErrors && fieldErrors.location ? true : false}
                                helperText={fieldErrors && fieldErrors.location ? fieldErrors.location : ''}/>
                        </Grid>
                    </Grid>

                    {error && <Alert severity="error">{error}</Alert>}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }} > Submit </Button>
                    <Button type="button" fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }} onClick={() => { props.onClose && props.onClose(false) }}> Cancel </Button>
                </Box>
            </Box>
        </Container>
    );
}
export default EditRacing