import { Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import apis from "../../../api"
import CommonDialog from "../../../Component/CommonDialog"
import { MemberLevelOption, SingleOptionList } from "../../../Component/MuiEx"
import { apiResult, formatDate, getUserSession } from "../../../Utils/Common"
import { CoachSelect } from "../../../Component/CoachSelector"

const MemberSettingDialog = (props) => {
    const { member, onClose, onSubmit } = props    
    const [fields,setField] = useState({id:member.id})
    const [error, setError] = useState()
    getUserSession(apis)    
    const onChange = (n,v)=>{        
        setField({...fields,[n]:v})
    }
    const handleSubmit = () => {
        let ff = {...fields}
        if(fields.level_expired && !fields.level_date) {
            ff.level_date = formatDate(new Date())
        }
        apis.memberSetting(ff).then(ret => {
            setError()
            apiResult(ret, data => {
                onSubmit && onSubmit(ff)
            }, setError)
        })
    }
    return <CommonDialog errorHint={error} dialogTitle={"Member Setting"} onClose={onClose} onSubmit={handleSubmit}>        
        <SingleOptionList direction="row" defaultValue={member.category} label="Set member category" items={["Saber","Foil","Epee"]} values={[0,1,2]} name="category" onChange={onChange}></SingleOptionList>
        <CoachSelect sx={{mt:2,mb:2}} label={"Choose member main coach"} name="main_coach" onError={setError} defaultValue={member.main_coach} onChange={(n,v)=>{onChange(n,v.id)}}/>
        <SingleOptionList direction="column" defaultValue={member.level} label="Set member level" items={MemberLevelOption().levels} values={MemberLevelOption().values} name="level" onChange={onChange}></SingleOptionList>
        <Stack direction={"row"}>
            <TextField margin="normal" type='date' fullWidth label="Begin Date" defaultValue={member.level_date?member.level_date:formatDate(new Date())} onChange={(e) => { onChange("level_date",e.target.value) }} InputLabelProps={{ shrink: true }} />
            <TextField margin="normal" type='date' fullWidth sx={{ ml: 1 }} label="Expired Date" defaultValue={member.level_expired} onChange={(e) => { onChange("level_expired",e.target.value) }} InputLabelProps={{ shrink: true }} />
        </Stack>
    </CommonDialog>
}

export default MemberSettingDialog