import { useState } from 'react';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Grid, Box, Typography, Alert, } from '@mui/material';
import { formToJson } from '../../../Utils/Common';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { SingleSelector } from '../../../Component/MuiEx';
import PhotoView from '../../../Component/PhotoView';

const EditItem = (props) => {
    const { data, onSubmit, onClose,categories,categoryids} = props
    const [fieldErrors, setFieldErrors] = useState()    
    const [error, setError] = useState()    
    const [category,setCategory] = useState(data?.category)
    const [photo,setPhoto] = useState(data?.photo)
    const handleSubmit = (event) => {
        setFieldErrors()
        setError()
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        const postData = formToJson(form)
        postData.id = data ? data.id : 0     
        postData.category = category   
        postData.photo = photo        
        onSubmit && onSubmit(postData, setError, setFieldErrors)
    };
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography component="h1" variant="h5">Add & Edit Item</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <SingleSelector items={categories} defaultValue={data?.category} values={categoryids} onChange={(n,v)=>{setCategory(v)}} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField required fullWidth id="product" defaultValue={data?.product} label="Product" name="product"
                                error={fieldErrors && fieldErrors.product ? true : false}
                                helperText={fieldErrors && fieldErrors.product ? fieldErrors.product : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField required fullWidth id="brand" defaultValue={data?.brand} label="Brand" name="brand"
                                error={fieldErrors && fieldErrors.brand ? true : false}
                                helperText={fieldErrors && fieldErrors.brand ? fieldErrors.brand : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <PhotoView width={"100px"} value={data?.photo} onChange={(filename)=>{setPhoto(filename)}} />   
                        </Grid>
                        
                        <Grid item xs={12} sm={12}>
                            <TextField required fullWidth id="price" label="Non-member Price" defaultValue={data?.price} name="price"
                                error={fieldErrors && fieldErrors.price ? true : false}
                                helperText={fieldErrors && fieldErrors.price ? fieldErrors.price : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField required fullWidth id="member_price" label="Member Price" name="member_price" defaultValue={data?.member_price}
                                error={fieldErrors && fieldErrors.member_price ? true : false}
                                helperText={fieldErrors && fieldErrors.member_price ? fieldErrors.member_price : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField required fullWidth id="remained" label="Inventory" defaultValue={data?.remained} name="remained"
                                error={fieldErrors && fieldErrors.remained ? true : false}
                                helperText={fieldErrors && fieldErrors.remained ? fieldErrors.remained : ''}
                            />
                        </Grid>                        
                    </Grid>

                    {error && <Alert severity="error">{error}</Alert>}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }} > Submit </Button>
                    <Button type="button" fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }} onClick={() => { props.onClose && props.onClose(false) }}> Cancel </Button>
                </Box>
            </Box>
        </Container>
    );
}
export default EditItem