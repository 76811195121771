import { Button, Typography } from "@mui/material"
import apis from "../../api"
import MyTable from "../../Component/MyTable"
import { apiResult, getUserSession } from "../../Utils/Common"

const { useEffect, useState } = require("react")
const { useNavigate } = require("react-router")

const Dashboard = () => {
    const navigate = useNavigate()
    // const firsturl = localStorage.getItem("firsturl")
    // useEffect(()=>{
    //     firsturl && navigate(firsturl)
    // },[firsturl])    
    const [datas, setDatas] = useState()
    const [applicants, setApplicants] = useState()
    const [totalApplicants, setTotalApplicants] = useState(0)
    const [header, setHeader] = useState()
    const [error, setError] = useState()

    getUserSession(apis)
    useEffect(() => {
        apis.loadBirthday().then(ret => {
            apiResult(ret, data => {
                setDatas(data)
            }, setError)
        })
        apis.loadAllApplicants(0, 20, 1).then(ret => {
            apiResult(ret, data => {
                setTotalApplicants(data.total)
                setApplicantsData(data.data)
            }, setError)
        })
    }, [])
    const setApplicantsData = (data) => {
        let ret = []
        let host = window.location.hostname
        let hosts = host.split('.')
        hosts.length >= 3 && hosts.splice(0, 1)
        let headers = [{ name: 'id', showName: 'ID' }, {
            name: "event", showName: 'Event', func: (v, idx, row) => {
                return <a href={"https://member." + hosts.join('.') + "/event/" + v.code} target={"win_" + v.code}>{v.name}</a>
            }
        }, { name: 'family', showName: 'Family' }, { name: 'femail', showName: 'Email' }, { name: 'fphone', showName: 'Phone' },
        {
            name: 'submitted', showName: 'Applicant Form', func: (v, idx, row) => {
                return row.submitted && (JSON.stringify(row.submitted).substring(0,40) + '...')
            }
        }]

        data.map((item, idx) => {
            let row = item
            if (row.event && row.event.sign == 1 && item.user_profile) {
                row.family = item.user_profile.name
                row.femail = item.user_profile.email
                row.fphone = item.user_profile.phone
            }
            row.submitted = {}
            let form = item.form ? JSON.parse(item.form) : []
            form.map(f => {
                f.items.map(subitem => {
                    // if (idx === 0) headers.push({ name: subitem.name, showName: subitem.name })
                    row.submitted[subitem.name] = subitem.value && (subitem.type == 'option' ? subitem.value.join(',') : (subitem.type == 'check' ? (subitem.value ? 'Yes' : '') : subitem.value))
                })
            })
            ret.push(row)
        })

        headers.push({
            name: 'pay_status', showName: 'Pay status', func: (v, idx, row) => {
                if (v == 1) {
                    return <Typography variant="body2">${row?.pay_amount}<br></br>{row?.pay_time}</Typography>
                } else {
                    return ''
                }
            }
        })
        headers.push({name:'create_time',showName:'Datetime',func:(v,idx,row)=>{
            return v.substring(0,10)
        }})
        headers.push({
            name:'applicants',showName:'Applicants',func:(v,idx,row)=>{
                return <Button size="small" variant="outlined" onClick={()=>{
                    navigate('/event/applicant/'+row.event.id)
                }}>View</Button>
            }
        })

        setHeader(headers)
        setApplicants(ret)
    }
    const handleChangePage = (page, rowPerPage) => {
        apis.loadAllApplicants(page, rowPerPage, 0).then(ret => {
            apiResult(ret, data => {
                setApplicantsData(data.data)

            }, setError)
        })
    }
    const TableHeader = [
        { name: 'id', showName: 'ID' },
        { name: 'name', showName: 'Name' },
        { name: 'birthday', showName: 'Birthday' },
    ]
    const ApplicantsTableHeader = [
        { name: 'id', showName: 'ID' },
        { name: 'name', showName: 'Name' },
        { name: 'birthday', showName: 'Birthday' },
    ]
    return <>
        <Typography variant="h6" sx={{p:2}}>Birthday reminder</Typography>
        {datas && datas.length > 0 && <MyTable
            height={650}
            rows={datas}
            totalRow={datas.length}
            headers={TableHeader}
            checkbox={false}
            rowsPerPage={datas.length}
            showPageination={false}
        />}
        <Typography variant="h6" sx={{p:2}}>Event Applicants</Typography>
        {applicants && applicants.length > 0 && <MyTable
            height={650}
            rows={applicants}
            totalRow={totalApplicants}
            headers={header}
            checkbox={false}
            onChangePage={handleChangePage}
            rowsPerPage={20}
        />}
    </>
}

export default Dashboard