import { Alert, Backdrop, Chip, IconButton, Paper, Toolbar, Tooltip, Typography } from "@mui/material"
import { useEffect, useRef,useState } from "react"
import { useNavigate, useParams } from "react-router"
import apis from "../../api"
import { MemberLevel, MemberStatus } from "../../Component/MuiEx"
import MyTable from "../../Component/MyTable"
import { apiResult, getUserSession } from "../../Utils/Common"
import MemberSettingDialog from "./fragement/MemberSettingDialog"

const MemberLevelLog = ()=>{
    const params = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error,setError] = useState()
    const [datas,setDatas] = useState()
    const [memberId,setMemberId] = useState(params.id)    
    const [totalCount,setTotal] = useState(0)
    const [currentMemberIdx,setCurrentMemberIdx] = useState(-1)
    getUserSession(apis)
    const handleOrder = (item) => {
        const neworder = orderField.order == 'desc' ? 'asc' : 'desc'
        setOrderField({ ...orderField, name: item.name, order: neworder })
        loaddatas(memberId,0,0, item.name, neworder)
    }
    const [orderField, setOrderField] = useState({ fields: ['id', 'name', 'postlevel','level','sdate'], name: 'id', order: 'desc' })

    const initPage = useRef(false)
    const loaddatas = (id,page,countdata,orderfield,order) =>{
        setMemberId(id)
        navigate('/member/levellog/'+id)
        setLoading(true)
        apis.memberLevelLog(id,page,20,countdata,orderfield,order).then(ret=>{
            setLoading(false)
            apiResult(ret,data=>{
                if(countdata == 1) {
                    setTotal(data.total)
                }
                setDatas(data.data)
            },setError)
        })
    }
    useEffect(()=>{
        if(initPage.current) return
        initPage.current = true
        loaddatas(params.id,0,1,'id','desc')
    },[])
    const handleChangePage = (page, rowsperpage) => {
        loaddatas(memberId,page,0,orderField.name,orderField.order)
    }
    const handleClickLevel = (row,idx)=>{
        setCurrentMemberIdx(idx)
    }
    const TableHeders = [
        { name: 'id', showName: 'ID' },        
        {
            name: 'name', showName: 'Member', func: (v, idx, row) => {
                const { icon, status } = MemberStatus(row.status)
                return <Chip size="small" onClick={()=>memberId != row.member_id &&loaddatas(row.member_id,0,1,orderField.name,orderField.order)} variant="outlined" title={status} icon={icon} label={row.member?.name} sx={{ m: "1px", border: "0px" }} />
            }
        },
        {
            name: 'level', showName: 'Current Level', func: (v, idx, row) => {
                const { level, color } = MemberLevel(row.member?.level)
                return <Chip size="small" onClick={()=>handleClickLevel(row,idx)} variant="contained" title={level} label={level} sx={{ m: "1px", color: color }} />
            }
        },
        {
            name: 'prelevel', showName: 'Pre-Level', func: (v, idx, row) => {
                const { level, color } = MemberLevel(row.prelevel)
                return <Chip size="small" variant="outlined" title={level} label={level} sx={{ m: "1px", color: color }} />
            }
        },
        {
            name: 'postlevel', showName: 'Post-Level', func: (v, idx, row) => {
                const { level, color } = MemberLevel(row.postlevel)
                return <Chip size="small" variant="outlined" title={level} label={level} sx={{ m: "1px", color: color }} />
            }
        },
        { name: 'sdate', showName: 'Date', },
    ]
    return (
        <>
            {loading && <Backdrop />}
            {memberId > 0 && <Paper sx={{ marginBottom: 2 }}>
                <Toolbar style={{ paddingLeft: 2 }}>                         
                    <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" ></Typography>
                    <Tooltip title="All member level change log">
                        <IconButton onClick={()=>loaddatas(0,0,1,orderField.name,orderField.order)}   size="small">All</IconButton>
                    </Tooltip>
                </Toolbar>
            </Paper>}
            {error && <Alert severity={"warning"} sx={{ width: '100%', marginTop: "5px", mb: 2 }} onClose={() => setError()}>{error}</Alert>}            
            {totalCount == 0 && <Alert severity={"info"}>Ops! There is not any data</Alert>}
            {totalCount > 0 && <MyTable
                height={650}
                rows={datas}
                totalRow={totalCount}
                headers={TableHeders}
                checkbox={false}
                rowsPerPage={20}
                order={orderField}
                onOrder={handleOrder}               
                onChangePage={handleChangePage}
            />}
            {currentMemberIdx >= 0 && <MemberSettingDialog member={datas[currentMemberIdx].member} onClose={() => setCurrentMemberIdx(-1)} onSubmit={(v) => {
                setCurrentMemberIdx(-1)
                let cc = [...datas]
                cc[currentMemberIdx].member.level = v
                setDatas(cc)
            }} />
            }
        </>

    )
}
export default MemberLevelLog